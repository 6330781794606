import { dayjs } from 'element-plus';
export const imgSize = async file => {
  const fileData = file;
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.onload = function (e) {
      const data = e.target?.result;
      const image = new Image();
      image.onload = function () {
        const width = image.width;
        const height = image.height;
        resolve({
          width,
          height
        });
      };
      image.src = data;
    };
    reader.readAsDataURL(fileData);
  });
};
export const verifyPwd = pwd => {
  let err = '';
  if (pwd.length < 7) {
    err = '密码不能少于7位';
  }
  if (!/[a-z]+/.test(pwd)) {
    err = '密码必需包括小写英文字母';
  }
  if (!/[A-Z]+/.test(pwd)) {
    err = '密码必需包括大写英文字母';
  }
  if (!/\d+/.test(pwd)) {
    err = '密码必需包括数字';
  }
  return err;
};
export const toJSON = jsonStr => {
  let ret;
  try {
    ret = JSON.parse(jsonStr);
  } catch (error) {
    ret = new Function(`${jsonStr}`)();
  }
  return ret;
};
export const getIntId = () => {
  const timeStr = Date.now().toString();
  const part5 = timeStr.slice(timeStr.length - 5);
  const randam = Math.floor(Math.random() * 20) + 1;
  return Number(`${part5}${randam}`);
};
export const encodeRichText = str => {
  return str.replaceAll(' ', '&nbsp;').replaceAll('\n', '<br />');
};
export const decodeRichText = (str = '') => {
  return str.replaceAll('&nbsp;', ' ').replaceAll('<br />', '\n');
};
export const is = (str, type) => {
  return Object.prototype.toString.call(str) === `[object ${type}]`;
};
export const canvas2File = (canvas, type = 'image/png', name = 'image.png') => {
  return new Promise((resolve, reject) => {
    canvas.toBlob(blob => {
      if (blob) {
        const file = new File([blob], name, {
          type
        });
        resolve(file);
      }
      reject(null);
    }, type);
  });
};
export const downloadUrl = (url, name) => {
  const a = document.createElement('a');
  a.download = name;
  a.href = url;
  a.style.position = 'fixed';
  a.style.top = '-9999px';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
export const logger = (title, msg, level = 'log') => {
  console.log(`${level}.[aos-admin]: ${title} ==> ${msg}, ${dayjs().format('YYYY-MM-DD HH:mm:ss')}`);
};