import { OptionItem } from '@/service/model/types';

export enum ColumnType {
  Tag = 'tag',
  Copy = 'copy',
  Mask = 'mask',
  StandardDate = 'standard_date',
}
export enum SearchConfigType {
  Input = 'input',
  UnionInput = 'union_input',
  Select = 'select',
  DatePicker = 'date_picker',
  DatePickerRange = 'date_picker_range',
}

export interface SearchItemConfig<
  PropKeys = AnyType,
  OptionValueType = AnyType
> {
  type: SearchConfigType;
  label?: string;
  prop: keyof PropKeys;
  width?: number;
  minWidth?: number;
  placeholder?: string;
  collapse?: boolean;
  options?: OptionItem<OptionValueType>[];
  asyncOptions?: () => Promise<OptionItem<OptionValueType>[]>;
  datePickerOpt?: {
    rangeSeparator?: string;
    startPlaceholder?: string;
    endPlaceholder?: string;
    valueFormat?: string | 'timestamp';
    start_field?: string;
    end_field?: string;
  };
  unionInputOpt?: {
    labels: string[];
    /**
     * 和labels一一对应, 选择labels中的第一项, 产出的值会赋值给fields中第一个字段
     */
    fields: string[];
  };
}

export interface SearchConfig {
  labelWidth?: number;
  cacheName?: string;
  items: SearchItemConfig[];
}
export interface ColumnItem<T = unknown> {
  prop?: keyof T;
  label?: string;
  width?: number;
  minWidth?: number;
  fixed?: 'left' | 'right';
  valueGetter?: (data: T) => string;
  renderer?: (data: T) => JSX.Element;
  /**
   * 预设的单元格样式, 如tag, copy等
   * @example type: ColumnType.Tag
   */
  type?: ColumnType;
  tooltip?: boolean;
  /**
   * 列的类型为Mask时, 点击眼睛会调用这个方法. 把此方法返回的内容展示出来
   * @param data
   * @returns
   */
  getPlainText?: (data: T) => Promise<string>;
  tagConfig?: {
    /**
     * 预设-值和tag type属性的对应关系
     */
    typeMap?: Record<
      string,
      'primary' | 'success' | 'info' | 'warning' | 'danger'
    >;
    /**
     * 预设-值和展示内容的对应关系, 如 data === 1 ? '开启' : '结束'
     */
    valueMap?: Record<string, string>;
    size?: 'large' | 'small';
    effect?: 'dark' | 'light' | 'plain';
  };
}
export interface TableConfig<T = unknown> {
  data: T[];
  loading?: boolean;
  page?: number;
  total?: number;
  size?: number;
  sizes?: number[];
  /**
   * total, sizes, prev, pager, next, jumper
   */
  layout?: string;
  columnDefs?: ColumnItem<T>[];
  searchDefs?: SearchConfig;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AnyObject = Record<string, any>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AnyType = any;
export type PaginationType = Omit<TableConfig, 'list'>;
