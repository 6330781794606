import {
  PrintTaskEntity,
  PrintTaskStatusEnum,
} from '@/service/client/Print.type';
import { PrintClient } from '@/service/client/PrintClient';
// import { nextTaskList, taskList } from '@/components/system/mock_data';

export type TaskDoneFunction = (data: PrintTaskEntity[]) => void;
export type TaskEventType = 'task_done' | 'data_change';

class TaskCenter {
  /**
   * 注册进来的消费者
   */
  private _consume: {
    task_done: { id: number; cb: TaskDoneFunction }[];
    data_change: { id: number; cb: TaskDoneFunction }[];
  } = {
    task_done: [],
    data_change: [],
  };
  /**
   * 轮询时间间隔
   */
  private _step = 2000;
  /**
   * 最后一次轮询的结果, 新结果进来如果有状态从 进行中 到了 完成. 则发出 task_done广播;
   */
  private _prev_data: PrintTaskEntity[] = [];
  /**
   * 是否可以接着轮询, 标识位
   */
  private _poll_able = false;
  /**
   * 轮询定时任务的句柄
   * @private
   */
  private _timer: number | undefined = undefined;
  /**
   * 订阅任务id
   * @private
   */
  private _counter = 0;
  /**
   * 连续查到空数据的次数
   * @private
   */
  private empty_times = 0;
  /**
   * mock数据, 请求次数
   * @private
   */
  private _test_num = 0;

  /**
   * 开始轮询任务, 两次没有查到活动中的任务 则会强制停止
   */
  public start() {
    if (this._poll_able) return;
    this._poll_able = true;
    this._poll();
  }

  public stop() {
    this._poll_able = false;
    this.empty_times = 0;
    clearTimeout(this._timer);
  }

  public subscribe(event: TaskEventType, fn: TaskDoneFunction) {
    const id = this.getId();
    this._consume[event].push({
      id,
      cb: fn,
    });
    return id;
  }

  public unSubscribe(event: TaskEventType, id: number) {
    this._consume[event] = this._consume[event].filter((d) => d.id !== id);
  }

  private getId() {
    return ++this._counter;
  }

  private _send_notify(event: TaskEventType, tasks: PrintTaskEntity[]) {
    this._consume[event].forEach((sub) => {
      sub.cb(tasks);
    });
  }

  private _poll() {
    this.getTasks()
      .then((res) => {
        if (!res) {
          return Promise.reject(false);
        }
        if (this._poll_able) {
          this._timer = setTimeout(() => {
            this._poll();
          }, this._step);
        }
      })
      .catch(() => {
        this.stop();
      });
  }

  private complareData(data: PrintTaskEntity[]) {
    if (this._prev_data.length > 0) {
      // 新来的数据 在 上次的快照中不存在
      const minusItems = this._prev_data.filter(
        (item) => data.find((d) => d.id === item.id) === undefined
      );

      this._send_notify('task_done', minusItems);
    }

    this._prev_data = data || [];
  }

  private async getTasks() {
    const res = await PrintClient.getTaskList({
      status: PrintTaskStatusEnum.RUNNING,
    });
    //#region 测试代码, 要删
    // const res = await PrintClient.getTaskList_Test();
    // switch (this._test_num) {
    //   case 0:
    //     res.data.list = taskList;
    //     break;
    //   case 1:
    //     res.data.list = nextTaskList(600, 40);
    //     break;
    //   case 2:
    //     res.data.list = nextTaskList(1000, 80);
    //     break;
    //   case 3:
    //     res.data.list = nextTaskList(1000, 120);
    //     break;
    //   case 4:
    //     res.data.list = nextTaskList(0, 0, 1);
    //     break;
    //   case 5:
    //     res.data.list = nextTaskList(1000, 10000, 2);
    //     break;
    // }
    // this._test_num++;
    //#endregion

    // 比较上次
    if (res.success) {
      console.log(res.data.list.map((d) => d.progress).join('--'));
      // 记录查到空数据的次数
      if (res.data.list.length === 0) {
        this.empty_times = this.empty_times + 1;
      } else {
        this.empty_times = 0;
      }
      // 如果连续3次查到空数据, 停止轮询
      if (this.empty_times >= 3) {
        this.stop();
      }
      this.complareData(res.data.list);
      this._send_notify('data_change', res.data.list);
      return true;
    }
    return false;
  }
}

const factory = (function () {
  let instance: TaskCenter | null = null;
  return {
    getInstance() {
      if (instance === null) {
        instance = new TaskCenter();
      }

      return instance;
    },
  };
})();

export default factory;
